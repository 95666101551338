import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./LeftAnimation.css";

function OTPVerification({ mobileNo, onClose }) { 
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const verifyOTP = async () => {
    setLoading(true);
    try {
      
      console.log("Mobile Number:", mobileNo);
      console.log("OTP Entered:", otp);

      const response = await axios.post(
        "https://shrishivshankarjewellers.com/exe_files/verify_otp.php",
        { MobileNo: mobileNo, OTP: otp },  
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.response === "Success") {
        navigate("/customer_contact"); 
      } else {
        setError(response.data.msg || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false); 
    }
};

  const handleSubmit = () => {
    if (otp.length !== 6 || isNaN(otp)) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }
    setError(""); 
    verifyOTP(); 
  };

  return (
    <div className="flex flex-col items-center justify-center animate-slideInFromLeft min-h-screen relative">
      <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
        Close
      </button>
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md animate-slideInFromLeft">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-4">
          Enter OTP
        </h2>

        <label className="block text-lg font-semibold mb-2">OTP</label>
        <input
          type="text"
          placeholder="Enter your OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value.replace(/[^0-9]/g, ""))}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-[#F0B90B] transition-all duration-300"
        />
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <button
          onClick={handleSubmit}
          className={`w-full bg-[#F0B90B] text-white py-3 px-6 rounded-lg shadow-lg hover:bg-[#d39e0b] transition-all duration-300 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
          disabled={loading}
        >
          {loading ? "Loading..." : "Verify OTP"}
        </button>
      </div>
    </div>
  );
}

export default OTPVerification;
