import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Make sure to import useNavigate

function Collection() {
  const [selected, setSelected] = useState("Gold");
  const [metalList, setMetalList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Function to map metal names to IDs
  const getMetalId = (metalName) => {
    switch (metalName) {
      case "Gold":
        return 1;
      case "Silver":
        return 2;
      case "Diamond":
        return 3;
      default:
        return 1;
    }
  };

  // Utility function to shuffle an array
  const shuffleArray = (array) => {
    return [...array].sort(() => Math.random() - 0.5); // Using -0.5 for a more even shuffle
  };

  useEffect(() => {
    const fetchMetalList = async () => {
      try {
        setLoading(true); // Ensure loading state is set to true when fetching
        setError(null); // Reset error before fetching
        const metalId = getMetalId(selected);
        const response = await axios.get(
          `https://shrishivshankarjewellers.com/exe_files/list_metal_products.php?MetalId=${metalId}`
        );
        setMetalList(response.data.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMetalList();
  }, [selected]);

  // Handling loading and error states
  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8">Error: {error}</div>;
  }

  // Filter and shuffle products, then limit to 8 items
  const filteredProducts = shuffleArray(
    metalList.filter((product) => {
      if (selected === "Gold" && product.MetalId === 1) return true;
      if (selected === "Silver" && product.MetalId === 2) return true;
      if (selected === "Diamond" && product.MetalId === 3) return true;
      return false;
    })
  ).slice(0, 8); // Change 6 to 8 to show 8 products

  const handleClick = (name) => {
    setSelected(name);
  };

  const handleBuyNow = (product) => {
    navigate(`/product/${product.ProductId}`, {
      state: { selectedProduct: product },
    });
  };

  return (
    <div className="max-w-7xl mx-auto mt-28 p-4">
      <h1 className="text-3xl font-bold mb-4 text-center">
        Our Collections: Simply Elegant
      </h1>
      <div className="flex justify-center space-x-4">
        {["Gold", "Silver", "Diamond"].map((metal) => (
          <button
            key={metal}
            className={`text-lg font-bold px-4 py-2 transition-colors duration-300 ${
              selected === metal
                ? "text-yellow-500 border-b-2 border-yellow-500"
                : "text-gray-600"
            }`}
            onClick={() => handleClick(metal)}
          >
            {metal}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div
              key={product.ProductId}
              className="bg-gray-100 p-5 rounded-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col"
            >
              <div className="mb-4 relative">
                <img
                  src={
                    product.PhotoPath
                      ? `https://shrishivshankarjewellers.com/dashboard/images/products/${product.PhotoPath}`
                      : "/path/to/fallback-image.jpg"
                  }
                  alt={product.ProductName || "Product Image"}
                  className="w-full h-48 object-cover rounded-lg transition-transform duration-300 hover:scale-105"
                  loading="lazy"
                />
              </div>
              <h3 className="text-2xl font-semibold mb-2">{product.ProductName}</h3>
              <div className="mb-4">
                {product.MRP && (
                  <span className="line-through text-gray-500 mr-2">
                    Rs. {product.MRP}
                  </span>
                )}
                <span className="text-xl font-bold text-[#a8741a]">
                  Rs. {product.OfferPrice}
                </span>
              </div>
              <p className="text-sm text-gray-400 mb-4">
                <span>Weight -</span> {product.Weight}
              </p>
              <p className="text-sm text-gray-400">{product.ShortDescription}</p>

              <div className="mt-auto flex justify-between items-center">
                <div className="flex items-center space-x-2 text-gray-400 cursor-pointer">
                  <FaHeart className="hover:scale-110 transition-transform duration-200" />
                  <span className="text-sm">Interested</span>
                </div>
                <button
                  onClick={() => handleBuyNow(product)}
                  className="px-4 py-2 bg-[#b9a02e] text-white rounded-lg hover:bg-[#875f14] transition-colors duration-300"
                >
                  Buy Now
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-lg text-center col-span-full">
            No products found in this category.
          </p>
        )}
      </div>
    </div>
  );
}

export default Collection;
