import React from 'react'
import Homepage from './Homepage'
import Types from '../Desin/Types'

import Collection from '../Collections/Collection'
import Gift from '../Desin/Gift'
import Categories from '../Categories/Categories'



function Home() {
  return (
    <div>
     {/* <Sample/> */}
    <Homepage/>
    <Categories/>
    <Types/>
    <Collection/>

    <Gift/>
    </div>
  )
}

export default Home