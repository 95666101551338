import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import LoginPage from "./LoginPage";

const ProductPage = () => {
  const { ProductId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Ensure hooks are called unconditionally
  const [quantity, setQuantity] = useState(1);
  const [showLogin, setShowLogin] = useState(false);

  // Get product data passed from CategoryPage
  const selectedProduct = location.state?.selectedProduct || {}; // Default to an empty object
  const images = location.state?.images || []; // Default to an empty array

  // Set the initial image and images array
  const [mainImage, setMainImage] = useState(selectedProduct.PhotoPath || "");
  const mainImageUrl = `https://shrishivshankarjewellers.com/dashboard/images/products/`;

  // Create the combined images array
  const productImages = [selectedProduct.PhotoPath, ...images].filter(Boolean); // Filter out any falsy values

  const handleQuantityChange = (action) => {
    setQuantity((prevQuantity) => {
      if (action === "increase") {
        return prevQuantity + 1;
      } else if (action === "decrease" && prevQuantity > 1) {
        return prevQuantity - 1;
      }
      return prevQuantity;
    });
  };

  const handleBuyNow = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const customerMobile = localStorage.getItem("customerMobile");

    if (!isLoggedIn || !customerMobile) {
      setShowLogin(true); // Show login page if not logged in
    } else {
      navigate("/checkout");
    }
  };

  // Display a loading message if selectedProduct data is not ready
  if (!selectedProduct.ProductName) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container mx-auto p-6 bg-[#F9F9F9] relative mt-10">
      <div className="flex flex-col lg:flex-row gap-10">
        {/* Main Image Section */}
        <div className="lg:w-1/2 flex flex-col items-center relative">
          <div className="w-full overflow-hidden rounded-xl shadow-lg mb-4">
            <img
              src={`${mainImageUrl}${mainImage}`}
              alt={selectedProduct.ProductName || "Product Image"}
              className="w-full h-auto object-cover transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            />
          </div>

          {/* Thumbnails Gallery */}
          <div className="flex gap-2 mt-4 overflow-x-auto">
            {productImages.map((image, index) => (
              <img
                key={index}
                src={`${mainImageUrl}${image}`}
                alt={`Thumbnail ${index + 1}`}
                className="w-20 h-20 object-cover rounded-md cursor-pointer border-2 border-transparent hover:border-yellow-500 transition-colors duration-300"
                onClick={() => setMainImage(image)}
              />
            ))}
          </div>
        </div>

        {/* Product Information Section */}
        <div className="lg:w-1/2">
          <h1 className="text-4xl font-extrabold mb-4 text-[#333] tracking-tight leading-tight">
            {selectedProduct.ProductName}
          </h1>
          <p className="text-lg font-light text-gray-600 mb-2">
            Product ID: {selectedProduct.ProductId}
          </p>
          <p className="text-3xl font-bold text-[#333] mb-2">
            {selectedProduct.ShortDescription}
          </p>
          <p className="text-green-500 font-semibold">You save 50%</p>
          <p className="my-6 text-gray-700 leading-relaxed">
            {selectedProduct.Specification}
          </p>

          {/* Quantity Selector */}
          <div className="mt-6">
            <label className="font-semibold mb-2 block">Quantity</label>
            <div className="flex items-center p-2 border rounded-md">
              <button
                className="text-xl p-2 bg-gray-200 hover:bg-gray-300 rounded-l-md"
                onClick={() => handleQuantityChange("decrease")}
              >
                -
              </button>
              <input
                type="text"
                value={quantity}
                readOnly
                className="w-12 text-center p-2 border-l border-r text-lg font-semibold"
              />
              <button
                className="text-xl p-2 bg-gray-200 hover:bg-gray-300 rounded-r-md"
                onClick={() => handleQuantityChange("increase")}
              >
                +
              </button>
            </div>
          </div>

          {/* Buy Now Button */}
          <div className="w-full mt-8">
            <button
              className="bg-[#F0B90B] text-white py-3 px-6 rounded-lg shadow-lg w-full hover:bg-yellow-600 transition-all duration-300 ease-in-out transform hover:scale-105"
              onClick={handleBuyNow}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>

      {showLogin && <LoginPage closeLogin={() => setShowLogin(false)} />}
    </div>
  );
};

export default ProductPage;
