import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

const LoginPage = ({ closeLogin }) => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    setError("");

    // Basic client-side validation
    if (!mobile || !password) {
      setError("Both mobile number and password are required.");
      setLoading(false);
      return;
    }

    // Mobile number validation (10 digits)
    if (!/^\d{10}$/.test(mobile)) {
      setError("Please enter a valid 10-digit mobile number.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://shrishivshankarjewellers.com/exe_files/login.php",
        { MobileNo: mobile, Pass: password }
      );

      if (response.data.response === "S") {
        localStorage.setItem("authToken", response.data.CustomerId);
        navigate("/customer_contact");
        closeLogin();
      } else {
        setError(response.data.msg || "Invalid credentials");
      }
    } catch (err) {
      console.error("Login error:", err);
      setError("An error occurred while logging in. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSignupSwitch = () => {
    navigate("/sign_up");
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300">
      <div className="bg-white p-8 rounded-2xl shadow-2xl w-full max-w-md transform transition-all duration-500 scale-95">
        <div className="flex justify-between mb-6">
          <h2 className="text-3xl font-bold text-gray-800">Login</h2>
          <IoMdClose
            className="text-2xl text-gray-600 cursor-pointer hover:text-red-500 transition-colors duration-300"
            onClick={closeLogin}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600 mb-2">
            Mobile Number
          </label>
          <input
            type="tel"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-yellow-400 transition duration-300"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder="Enter your mobile number"
            maxLength={10}
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-600 mb-2">
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-yellow-400 transition duration-300"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
          />
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="text-sm text-gray-500 mt-1 hover:text-gray-700 transition duration-300"
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>

        {error && <div className="text-red-500 mb-4">{error}</div>}

        <button
          className="w-full bg-gradient-to-r from-yellow-400 to-yellow-500 text-white py-3 rounded-lg shadow-lg hover:from-yellow-500 hover:to-yellow-600 transition-transform transform hover:scale-105 duration-300"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Logging in..." : "Login"}
        </button>

        <div className="mt-4 text-right">
          <button
            onClick={handleSignupSwitch}
            className="text-blue-500 hover:underline transition duration-300"
          >
            Switch to Signup
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
