import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaSearch,
  FaHeart,
  FaShoppingCart,
  FaChevronDown,
  FaTimes,
  FaPhoneAlt,
  FaStore ,
} from "react-icons/fa";
import logo from "../../img/Shivshankar logo.png";
import { Link } from "react-router-dom";

const Navbar = ({
  categories,
  fetchProductsBySubCategory,
  metals = [],
  fetchProductsByMetal,
}) => {
  const navigate = useNavigate();

  // Handle clicks for subcategories
  const handleSubCategoryClick = (subCategoryId) => {
    if (!subCategoryId) {
      console.error("Invalid subCategoryId:", subCategoryId);
      return;
    }
    fetchProductsBySubCategory(subCategoryId);
    navigate(`/category/${subCategoryId}`);
  };

  // Handle clicks for metals
  const handleMetalClick = (metalId) => {
    if (!metalId) {
      console.error("Invalid metalId:", metalId);
      return;
    }
    fetchProductsByMetal(metalId); // This should be a valid function passed as a prop
    navigate(`/metal/${metalId}`);
  };
  // Manually set metals (Gold, Silver, Diamond)
  const metalOptions = [
    { MetalId: 1, MetalName: "Gold" },
    { MetalId: 2, MetalName: "Silver" },
    { MetalId: 3, MetalName: "Diamond" },
  ];

  const [isSticky, setIsSticky] = useState(false);
  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Toggle mini cart visibility
  const toggleMiniCart = () => {
    setIsMiniCartOpen(!isMiniCartOpen);
  };

  // Close mini cart
  const closeMiniCart = () => {
    setIsMiniCartOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [rates, setRates] = useState({
    Gold24: null,
    Gold22: null,
    Gold18: null,
    Silver: null,
  });
  const [loading, setLoading] = useState(false);

  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Fetch rates from backend
  const fetchRates = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://shrishivshankarjewellers.com/exe_files/gold_rates.php"
      );

      console.log("Response data:", response.data);

      setRates({
        Gold24: response.data.Gold24,
        Gold22: response.data.Gold22,
        Gold18: response.data.Gold18,
        Silver: response.data.Silver,
      });

      console.log("Updated rates:", {
        Gold24: response.data.Gold24,
        Gold22: response.data.Gold22,
        Gold18: response.data.Gold18,
        Silver: response.data.Silver,
      });
    } catch (error) {
      console.error("Error fetching rates:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      console.log("Fetching rates...");
      fetchRates();
    }
  }, [isOpen]);

  return (
    <header className="relative">
      <div
        className={`bg-black border-b ${
          isSticky ? "border-gray-700" : "border-gray-700"
        }`}
      >
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center py-3">
            <div>
              <ul className="flex space-x-4">
                <li className="bg-gray-800 hover:bg-yellow-600 text-gray-400 rounded-full w-8 h-8 flex items-center justify-center transition-colors">
                  <FaFacebookF />
                </li>
                <li className="bg-gray-800 hover:bg-yellow-600 text-gray-400 rounded-full w-8 h-8 flex items-center justify-center transition-colors">
                  <FaTwitter />
                </li>
                <li className="bg-gray-800 hover:bg-yellow-600 text-gray-400 rounded-full w-8 h-8 flex items-center justify-center transition-colors">
                  <FaInstagram />
                </li>
                <li className="bg-gray-800 hover:bg-yellow-600 text-gray-400 rounded-full w-8 h-8 flex items-center justify-center transition-colors">
                  <FaLinkedinIn />
                </li>
                <li className="bg-gray-800 hover:bg-yellow-600 text-gray-400 rounded-full w-8 h-8 flex items-center justify-center transition-colors">
                  <FaYoutube />
                </li>
              </ul>
            </div>
            <div>
              <h1 className="text-xl font-bold text-gray-300 uppercase tracking-wide font-poppins font-serif">
                Our Legacy{" "}
                <span className="text-yellow-300 font-sans">1997</span>
              </h1>
            </div>

            <div className="z-50">
              <ul className="flex space-x-6">
                <li className="">
                    <p><FaStore/></p>
                    <p>Store Locator</p>
                </li>
                <li className="relative group">
                  <button
                    className="text-white hover:text-yellow-600 flex items-center focus:outline-none"
                    onClick={toggleDropdown}
                  >
                    Metal Rates <FaChevronDown className="ml-1 text-sm" />
                  </button>
                  <ul className="absolute mt-2 w-72 bg-[#FAF6EE] rounded-lg shadow-lg text-black opacity-0 transform -translate-y-5 scale-95 transition-all duration-300 group-hover:opacity-100 group-hover:translate-y-0 group-hover:scale-100">
                    <h1 className="text-[#7C0000] px-4 py-2 font-bold text-2xl">
                      Today's Gold Rate
                    </h1>
                    <p className="px-4 py-1 text-sm text-gray-500">
                      {new Date().toLocaleDateString()}
                    </p>
                    {loading ? (
                      <li className="px-4 py-2">Loading...</li>
                    ) : (
                      <>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          Gold 24K: ₹{" "}
                          {rates.Gold24
                            ? `${rates.Gold24} /10 gm`
                            : "Data not available"}
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          Gold 22K: ₹{" "}
                          {rates.Gold22
                            ? `${rates.Gold22} /10 gm`
                            : "Data not available"}
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          Gold 18K: ₹{" "}
                          {rates.Gold18
                            ? `${rates.Gold18} /10 gm`
                            : "Data not available"}
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          Silver: ₹{" "}
                          {rates.Silver
                            ? `${rates.Silver}/ kg`
                            : "Data not available"}
                        </li>
                      </>
                    )}
                  </ul>
                </li>

                <li className="relative group">
                  <button className="text-white hover:text-yellow-600 flex items-center focus:outline-none">
                    My Account <FaChevronDown className="ml-1 text-sm" />
                  </button>
                  <ul className="absolute left-0 mt-2 w-32 bg-gray-800 text-white text-sm shadow-lg opacity-0 transform translate-y-2 transition-opacity duration-300 group-hover:opacity-100 group-hover:visible">
                   
                    <li className="block px-4 py-2 hover:bg-yellow-600">
                      My Account
                    </li>
                    <li className="block px-4 py-2 hover:bg-yellow-600">
                     <a href="https://shrishivshankarjewellers.com/dashboard/">Admin</a> 
                    </li>
                    <li className="block px-4 py-2 hover:bg-yellow-600">
                      Shopping Cart
                    </li>
                    <li className="block px-4 py-2 hover:bg-yellow-600">
                      Wishlist
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`header_middel border-b bg-[#C62727] ${
          isSticky ? "border-gray-700" : "border-gray-200"
        } py-2`}
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center ">
            {/* Contact Section */}
            <div className="w-full lg:w-3/12 flex items-center mb-6 lg:mb-0 ">
              <div className="flex items-center">
                <div className="contact_icon mr-6 text-white">
                  <p aria-label="Helpline">
                    <FaPhoneAlt />
                  </p>
                </div>
                <div className="contact_box border-r border-gray-300 pr-6">
                  <p className="text-white text-sm">
                    Inquiry / Helpline:{" "}
                    <a
                      href="tel:1234567894"
                      className="text-yellow-500 hover:text-yellow-600"
                    >
                      1234567894
                    </a>
                  </p>
                </div>
              </div>
            </div>

            {/* Logo Section */}
            <div className="w-full lg:w-5/12 flex justify-center ml-20 ">
              <div className="logo">
                <a href="index.html">
                  <img
                    src={logo}
                    alt="Logo"
                    className="h-16 w-auto md:h-20 lg:h-24"
                  />
                </a>
              </div>
            </div>

            {/* Icons and Mini Cart */}
            <div className="w-1/2 lg:w-3/12 flex justify-end items-center space-x-4 z-20">
              {/* Search Icon */}
              <div className="relative group">
                <button className="w-12 h-12 flex items-center justify-center border border-white text-white rounded hover:text-yellow-500 transition-colors focus:outline-none">
                  <FaSearch className="text-xl" />
                </button>
                <div className="absolute right-0 mt-2 w-72 bg-white border border-gray-300 rounded shadow-lg opacity-0 group-hover:opacity-100 group-hover:visible transition-opacity duration-300 hidden">
                  <form action="#" className="relative">
                    <input
                      type="text"
                      placeholder="Search Product ...."
                      className="w-full px-4 py-3 bg-transparent border-none focus:outline-none text-sm text-gray-700"
                    />
                    <button
                      type="submit"
                      className="absolute top-0 right-0 px-4 py-3 text-xl text-white hover:text-yellow-500 focus:outline-none"
                    >
                      <FaSearch />
                    </button>
                  </form>
                </div>
              </div>

              {/* Wishlist Icon */}
              <div className="w-12 h-12 flex items-center justify-center border border-white text-white rounded hover:text-yellow-500 transition-colors">
                <FaHeart className="text-xl" />
              </div>

              {/* Mini Cart Button */}
              <div className="relative">
                <button
                  onClick={toggleMiniCart}
                  className="flex items-center border border-gray-300 text-white px-4 py-2 rounded hover:text-yellow-500 transition-colors focus:outline-none"
                >
                  <FaShoppingCart className="text-lg mr-2" />
                  <span className="cart_text_quantity text-sm">Rs. 67,598</span>
                  <FaChevronDown className="text-lg ml-2" />
                  <span className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                    2
                  </span>
                </button>

                {/* Mini Cart Dropdown */}
                {isMiniCartOpen && (
                  <div className="mini_cart fixed top-0 right-0 w-80 h-full bg-white border-l border-gray-300 shadow-lg transform translate-x-0 transition-transform duration-500 ease-in-out z-50">
                    <div className="flex justify-between items-center border-b border-gray-300 px-4 py-3">
                      <h3 className="text-lg font-semibold text-gray-800">
                        Cart
                      </h3>
                      <button
                        onClick={closeMiniCart}
                        className="text-2xl text-gray-800 hover:text-yellow-500 focus:outline-none"
                      >
                        <FaTimes />
                      </button>
                    </div>
                    <div className="p-4">
                      {/* Cart Item 1 */}
                      <div className="flex items-center mb-4">
                        <div className="cart_info flex-1">
                          <p className="text-sm font-medium text-gray-800 hover:text-yellow-500">
                            Earrings
                          </p>
                          <span className="block text-xs text-gray-500">
                            Qty: 1
                          </span>
                          <span className="block text-sm text-yellow-500">
                            Rs. 54,599
                          </span>
                        </div>
                        <div className="cart_remove">
                          <p className="text-gray-500 hover:text-yellow-500 text-lg">
                            <FaTimes />
                          </p>
                        </div>
                      </div>
                      {/* Cart Item 2 */}
                      <div className="flex items-center mb-4">
                        <div className="cart_info flex-1">
                          <p className="text-sm font-medium text-gray-800 hover:text-yellow-500">
                            Bracelet
                          </p>
                          <span className="block text-xs text-gray-500">
                            Qty: 1
                          </span>
                          <span className="block text-sm text-yellow-500">
                            Rs. 12,999
                          </span>
                        </div>
                        <div className="cart_remove">
                          <p className="text-gray-500 hover:text-yellow-500 text-lg">
                            <FaTimes />
                          </p>
                        </div>
                      </div>
                      {/* Cart Total */}
                      <div className="flex justify-between border-t border-gray-300 pt-4">
                        <span className="text-sm text-gray-800">Subtotal:</span>
                        <span className="text-sm text-yellow-500">
                          Rs. 67,598
                        </span>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-100 flex justify-between">
                      <button className="w-full bg-gray-800 text-white text-center py-2 rounded hover:bg-yellow-500 transition-colors mr-2">
                        View Cart
                      </button>
                      <button className="w-full bg-yellow-500 text-white text-center py-2 rounded hover:bg-yellow-600 transition-colors">
                        Checkout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Menu */}
      <div
        className={`header_bottom sticky-header ${
          isSticky ? "sticky bg-white bg-opacity-90 shadow-md" : ""
        } transition-all duration-100 ease-in-out`}
      >
        <div className="container mx-auto px-4 py-2">
          <div className="flex items-center justify-between">
            <div
              cla
              ssName={`logo_sticky ${isSticky ? "block" : "hidden"} mr-9`}
            >
              <Link to="/"> </Link>
            </div>

            {/* Main Menu */}
            <div className="flex-grow text-center">
              <nav>
                <ul className="flex items-center justify-center space-x-6 text-gray-800">
                  {/* Home Menu Item */}
                  <Link to="/">
                    <li className="uppercase font-medium text-lg text-gray-800 hover:text-yellow-500">
                      Home
                    </li>
                  </Link>

                  {/* Category Menu Item */}
                  <li className="relative group z-50">
                    <button className="flex items-center uppercase font-medium text-lg text-gray-800 hover:text-yellow-500 focus:outline-none">
                      Category <FaChevronDown className="ml-1 text-sm" />
                    </button>
                    <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 bg-white border border-gray-200 rounded-md shadow-lg opacity-0 group-hover:opacity-100 group-hover:visible scale-95 group-hover:scale-100 transition-all duration-300 invisible">
                      <div className="flex space-x-8 p-6">
                        {categories && categories.length > 0 ? (
                          categories.map((category) => (
                            <div key={category.CategoryId} className="w-1/3">
                              <p className="block uppercase font-semibold text-gray-800 hover:text-yellow-500 mb-6">
                                {category.CategoryName}
                              </p>
                              <ul>
                                {category.SubCategories &&
                                category.SubCategories.length > 0 ? (
                                  category.SubCategories.map((subCategory) => (
                                    <li
                                      key={subCategory.SubCategoryId}
                                      onClick={() =>
                                        handleSubCategoryClick(
                                          subCategory.SubCategoryId
                                        )
                                      }
                                      className="cursor-pointer hover:text-yellow-500"
                                    >
                                      {subCategory.SubCategoryName}
                                    </li>
                                  ))
                                ) : (
                                  <li className="text-gray-500 italic">
                                    No subcategories available
                                  </li>
                                )}
                              </ul>
                            </div>
                          ))
                        ) : (
                          <p className="text-gray-500 italic">
                            No categories available
                          </p>
                        )}
                      </div>
                    </div>
                  </li>

                  {/* About Us Link */}
                  <Link to="/about">
                    <li className="uppercase font-medium text-lg text-gray-800 hover:text-yellow-500">
                      About Us
                    </li>
                  </Link>

                  {/* Metal Menu Item */}
                  <li className="relative group z-50">
                    <button className="flex items-center uppercase font-medium text-lg text-gray-800 hover:text-yellow-500 focus:outline-none">
                      Metal <FaChevronDown className="ml-1 text-sm" />
                    </button>
                    <div className="absolute left-1/2 w-48 transform -translate-x-1/2 top-full mt-2 bg-white border border-gray-200 rounded-md shadow-lg opacity-0 group-hover:opacity-100 group-hover:visible scale-95 group-hover:scale-100 transition-all duration-300 invisible">
                      <div className="space-y-2 p-4">
                        {metalOptions.length > 0 ? (
                          metalOptions.map((metal) => (
                            <div key={metal.MetalId} className="menu-item">
                              <ul>
                                <li
                                  onClick={() =>
                                    handleMetalClick(metal.MetalId)
                                  } // Metal click handling
                                  className="cursor-pointer text-gray-600 hover:text-yellow-500"
                                >
                                  {metal.MetalName}
                                </li>
                              </ul>
                            </div>
                          ))
                        ) : (
                          <p className="italic text-gray-500">
                            No metals available
                          </p>
                        )}
                      </div>
                    </div>
                  </li>

                  {/* Contact Us Link */}
                  <Link to="/contact">
                    <li className="uppercase font-medium text-lg text-gray-800 hover:text-yellow-500">
                      Contact Us
                    </li>
                  </Link>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
