import React from "react";
import { MdLocalPhone } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import bgimg from "../../img/slider-2.png";

function Contact() {
  return (
    <div>
      {/* Header with Background Image */}
      <div
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="text-center py-36"
      >
        <h1 className="text-3xl font-bold uppercase text-[#C62727]">Contact Us</h1>
      </div>

      <div className="p-8">
        <div className="grid sm:grid-cols-2 items-start gap-20 my-6 mx-auto max-w-4xl bg-white text-[#333] font-sans">
          <div>
            {/* Call Section */}
            <div className="mt-12">
              <h2 className="text-lg text-[#C62727] font-extrabold">Call</h2>
              <ul className="mt-3">
                <li className="flex items-center">
                  <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                    <MdLocalPhone className="text-[#C62727] font-bold" aria-label="Phone Icon" />
                  </div>
                  <a href="tel:+918734958723" className="text-gray-900 text-sm ml-3">
                    <small className="block font-bold">Call</small>
                    <strong className="text-lg font-bold">+91 8734958723</strong>
                  </a>
                </li>
              </ul>
            </div>

            {/* Email Section */}
            <div className="mt-12">
              <h2 className="text-lg text-[#C62727] font-extrabold">Email</h2>
              <ul className="mt-3">
                <li className="flex items-center">
                  <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                    <IoMdMail className="text-[#C62727] font-bold" aria-label="Mail Icon" />
                  </div>
                  <a href="mailto:samvedanafoundation.sangli@gmail.com" className="text-gray-900 text-sm ml-3">
                    <small className="block font-bold">Mail</small>
                    <strong className="text-lg font-bold">shreeshivshankarjewellers@gmail.com</strong>
                  </a>
                </li>
              </ul>
            </div>

            {/* Address Section */}
            <div className="mt-12">
              <h2 className="text-lg text-[#C62727] font-extrabold">Address</h2>
              <ul className="mt-3">
                <li className="flex items-center">
                  <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                    <FaLocationDot className="text-[#C62727] font-bold" aria-label="Location Icon" />
                  </div>
                  <p className="text-gray-900 text-sm ml-3">
                    <small className="block font-bold">Address</small>
                    <strong className="text-lg font-bold">
                      Godavari Plaza, Post Office, Bus Stand Road-Karad, Karad - 415110 (Near Post Office)
                    </strong>
                  </p>
                </li>
              </ul>
            </div>

            {/* Call and Direction Buttons */}
            <div className="flex space-x-4 mb-4 mt-4">
              <a
                href="tel:+918734958723"
                className="bg-[#C62727] hover:bg-[#7C0000] text-white py-2 px-4 rounded"
              >
                Call
              </a>
              <a
                href="https://maps.app.goo.gl/7FzdKGbNXD7X9NjW6"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#C62727] hover:bg-orange-800 text-white py-2 px-4 rounded"
              >
                Get Direction
              </a>
            </div>
          </div>

          {/* Enquiry Form */}
          <form className="space-y-4">
            <h1 className="text-[#C62727] font-bold text-xl">Enquire now</h1>
            <input
              type="text"
              placeholder="Name"
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:outline-[#C62727]"
            />
            <input
              type="email"
              placeholder="Email"
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:outline-[#C62727]"
            />
            <input
              type="text"
              placeholder="Subject"
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:outline-[#C62727]"
            />
            <textarea
              placeholder="Message"
              rows="6"
              className="w-full rounded-md px-4 bg-gray-100 text-sm pt-3 outline-none focus:outline-[#C62727]"
            ></textarea>
            <button
              type="submit"
              className="text-white bg-[#C62727] hover:bg-orange-800 font-semibold rounded-md text-sm px-4 py-3 w-full"
            >
              Send
            </button>
          </form>
        </div>

        {/* Google Map Embed */}
        <div className="mt-8">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d952.4062443263343!2d74.17979282227894!3d17.285369206434144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc183ee8e7b0037%3A0xeb06f431c6190654!2sShivshankar%20Jewellers!5e0!3m2!1sen!2sin!4v1731309018233!5m2!1sen!2sin"
            className="w-full h-64 rounded-lg border-0"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
