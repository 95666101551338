import React from 'react'
import bgimg from '../../img/background.jpg'
import About_img1 from '../../img/shiv-about (2).jpg';
import About_img2 from '../../img/shiv-about2.jpg';

function About() {
  return (
    <div className=' '>
    <div >
      <div className='w-full h-[100vh] flex  mb-20 '>
        <div className='ml-72'>
          <img className='w-[40vw] h-[60vh] top-72 mt-28' src={About_img1} />
        </div>
        <div className='absolute left-32 bottom-2'>
          <img className='w-[20vw] h-[30vh] ' src={About_img2} />
        </div>
        <div className="w-full lg:w-2/5 h-auto bg-white shadow-xl p-8 lg:p-10 rounded-lg border border-yellow-200">
        <h1 className='text-base font-bold text-orange-800'>About Us</h1>
          <h2 className='font-semibold py-3'>Shri Shivshankar Jewellers, Karad has been in the business since 1997.</h2>
          <h2 className='py-3'>Shri Shivshankar Jewellers is a family business founded by Shri Arun Ramrav Jagtap in Karad in 1997. Starting with a small shop, he worked hard with honesty and a customer-centric approach. Slowly, he gained popularity in nearby villages and expanded his customer base from 100s to 1000s. By 2005, Shri Shivshankar Jewellers became a leading jewellery brand in Karad.
            However, in 2007, Shri Shivshankar Jewellers family lost him to major illness. </h2>
          <h2 className='py-3'> After Arun Tatya Jagtap's passing, his sons took over their father's legacy Shri Shivshankar Jewellers with the same passion. They continued to expand, building a successful jewellery business in Karad. Now, they house a 2000 sq. ft. showroom offering gold, silver, diamond, and platinum jewelry. They also provide a wide variety of designs with well-trained staff to serve their loyal customers. Despite challenges like COVID-19, they maintained growth and a strong reputation in the Jewellery Business.</h2>
          <h2 className='py-3'> Shri Shivshankar Jewellers offers a large collection of traditional and modern jewelry designs. They prioritize selling pure and hallmarked gold jewelry. Known for events like Silver Payal Mahotsav and Suvarn Sanchay Yojana, they have built trust through honesty, humbleness and quality. Their journey from a 200 sq. ft. Showroom to a 2000 sq. ft. showroom was challenging but achieved through hard work, transperancy, customer loyalty and passion.            </h2>
        </div>
      </div>
    </div>
    </div>
  )
}

export default About



