import React from 'react';
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import logo from '../../img/Shivshankar-17 copy 1234.png';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-10 ">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <img src={logo} alt="Logo" className="h-20  mb-4" />
            <p className="text-sm text-gray-400">Providing high-quality jewelry since 1997.</p>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h6 className="text-lg font-semibold mb-4">Services</h6>
            <ul>
              <li className="mb-2text-gray-400 hover:text-white transition">Home</li>
              <li className="mb-2text-gray-400 hover:text-white transition">About</li>
              <li className="mb-2text-gray-400 hover:text-white transition">Design</li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h6 className="text-lg font-semibold mb-4">Company</h6>
            <ul>
              <li className="mb-2">
                <h1 className="text-gray-400">Call</h1>
                <a href="tel:9876543210" className="text-gray-400 hover:text-white transition">9876543210</a>
              </li>
             
            </ul>
            <div className="mt-4">
              <h1 className="text-lg font-semibold text-gray-400 mb-2">Address</h1>
              <p className="text-sm text-gray-400">Godavari Plaza, near Head Office, Shaniwar Peth, Karad, Maharashtra 415110</p>
            </div>
          </div>
          <div className="w-full md:w-1/4">
            <h6 className="text-lg font-semibold mb-4">Social</h6>
            <div className="flex space-x-4">
              <p className="text-gray-400 hover:text-white transition">
                <FaFacebook size={24} />
              </p>
              <p className="text-gray-400 hover:text-white transition">
                <IoLogoWhatsapp size={24} />
              </p>
              <p className="text-gray-400 hover:text-white transition">
                <FaInstagramSquare size={24} />
              </p>
              <p className="text-gray-400 hover:text-white transition">
                <MdEmail size={24} />
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10 border-t border-gray-700 pt-6 text-center">
          <p className="text-xs text-gray-500">&copy; 2024 Shree Shivshankar Jewellers. All Rights Reserved. Developed by <a href="https://www.businessmantra.info/index.php" className="text-blue-400 hover:text-blue-500 transition">Business Mantra</a>.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
