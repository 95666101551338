import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./LeftAnimation.css";

const SignUpPage = () => {
  const [customerName, setCustomerName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const isValidMobile = (mobile) => /^[1-9]{1}[0-9]{9}$/.test(mobile);
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleSignUp = async () => {
    setErrorMessage("");

    if (!mobile) {
      setErrorMessage("Mobile number is required.");
      return;
    }

    if (!isValidMobile(mobile)) {
      setErrorMessage("Please enter a valid 10-digit mobile number.");
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    try {
      // Make the POST request
      const response = await axios.post(
        "https://shrishivshankarjewellers.com/exe_files/signup.php",
        {
          CustomerName: customerName,
          MobileNo: mobile,
          Email: email,
          Pass: password,
        }
      );

      console.log("Sign-Up Response:", response.data);

      if (response.data.response === "Error") {
        setErrorMessage(response.data.msg || "An error occurred. Please try again.");
      } else if (response.data.response === "Success") {
        setShowLogin(true);
        navigate("/customer_name");
      } else {
        // Handle unexpected responses
        setErrorMessage("Unexpected response from the server. Please try again.");
      }
    } catch (error) {
      console.error("Error during sign-up:", error);
      setErrorMessage("There was an error during sign-up. Please try again.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center animate-slideInFromLeft">
      <div className="min-h-screen w-full flex items-center justify-center bg-transparent">
        <div
          className={`bg-white p-8 rounded-xl shadow-lg w-full max-w-md ${
            showLogin ? "animate-fadeInUp" : "animate-slideInFromLeft"
          }`}
        >
          <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">
            Sign Up
          </h2>

          {errorMessage && (
            <div className="mb-4 text-red-600 text-center">{errorMessage}</div>
          )}

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Full Name
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Enter your full name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Mobile Number
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your mobile number"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Password
            </label>
            <input
              type="password"
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Confirm Password
            </label>
            <input
              type="password"
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
            />
          </div>

          <button
            className="w-full bg-[#F0B90B] text-white py-3 rounded-lg shadow-lg hover:bg-[#d39e0b] transition-all duration-300"
            onClick={handleSignUp}
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
