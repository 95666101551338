import React, { useEffect, useMemo } from "react"; 
import { useParams, useNavigate } from "react-router-dom"; 
import { FaHeart } from "react-icons/fa";

const MetalPage = ({ products = [], addToCart }) => {
  const navigate = useNavigate();  
  const { metalId } = useParams(); // Get metalId from URL params
  const parsedMetalId = parseInt(metalId, 10); // Parse metalId to an integer

  // Memoize filteredProducts to avoid recalculating on every render
  const filteredProducts = useMemo(() => {
    if (!parsedMetalId) return [];  // Return empty array if metalId is invalid
    return products.filter((product) => product.MetalId === parsedMetalId);
  }, [products, parsedMetalId]);

  // Handle Buy Now click
  const handleBuyNow = (product) => {
    navigate(`/product/${product.ProductId}`, {
      state: { selectedProduct: product },  
    });
  };

  // Error handling for invalid metalId
  useEffect(() => {
    if (!parsedMetalId || isNaN(parsedMetalId)) {
      console.error("Invalid metalId:", metalId);
      // Optionally navigate to a default page or show a user-friendly message
      navigate("/");  // Redirect to home page or category list
    }
  }, [metalId, parsedMetalId, navigate]);

  return (
    <section className="py-12 bg-white text-black relative">
      <div className="container mx-auto px-4 max-w-screen-xl">
        <h1 className="text-3xl font-bold mb-4 text-center">Category Products</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div
                key={product.ProductId}
                className="bg-gray-100 bg-opacity-90 p-5 rounded-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col"
              >
                <div className="mb-4 relative">
                  <img
                    src={
                      product.PhotoPath
                        ? `https://shrishivshankarjewellers.com/dashboard/images/products/${product.PhotoPath}`
                        : '/path/to/fallback-image.jpg' // Update this URL as needed
                    }
                    alt={product.ProductName || 'Product Image'}
                    className="w-full h-48 object-cover rounded-lg transform transition-transform duration-300 hover:scale-105"
                    loading="lazy"
                  />
                </div>

                <h3 className="text-2xl font-semibold mb-2">{product.ProductName}</h3>
                <div className="price_box mb-4">
                  {product.MRP && (
                    <span className="line-through text-gray-500 mr-2">Rs. {product.MRP}</span>
                  )}
                  <span className="text-xl font-bold text-[#a8741a]">
                    Rs. {product.OfferPrice}
                  </span>
                </div>
                <div className="mb-4 flex-grow">
                  <p className="text-sm text-gray-400">{product.ShortDescription}</p>
                </div>
                <div className="mt-auto flex justify-between items-center">
                  <div className="flex items-center space-x-2 text-gray-400 cursor-pointer">
                    <FaHeart className="hover:scale-110 transition-transform duration-200" />
                    <span className="text-sm">Interested</span>
                  </div>
                  <button
                    onClick={() => handleBuyNow(product)}  
                    className="px-4 py-2 bg-[#b9a02e] text-white rounded-lg hover:bg-[#875f14] transition-colors duration-300"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-lg">No products found in this category.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default MetalPage;
