import React, { useState, useEffect } from 'react';
import axios from 'axios'; 

const Homepage = () => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = 5000; 

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, slideInterval);
    return () => clearInterval(interval);
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://shrishivshankarjewellers.com/exe_files/banners.php');
        console.log("Fetched data:", response.data); 
        const formattedSlides = response.data.map((item) => ({
          bgImage: `https://shrishivshankarjewellers.com/dashboard/images/banners/${item.PhotoPath}`,
          title: item.Title || "Sample Title", 
          subtitle: item.ShortDescription || "Sample Subtitle", 
          offer: "Special Offer",
          price: "$1000"
        }));
        console.log("Formatted slides:", formattedSlides); 
        setSlides(formattedSlides); 
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  // Check if slides have loaded before rendering the carousel
  if (slides.length === 0) {
    return <div>Loading...</div>; 
  }

  const slide = slides[currentSlide];

  return (
    <div className="relative mb-9">
      <div
        className="w-full h-96 md:h-[600px] bg-center bg-cover flex items-center transition-all duration-700 ease-in-out"
        style={{ backgroundImage: slide ? `url(${slide.bgImage})` : 'none' }}
      >
        {slide && (
          <div className="container mx-auto px-4">
            <div className="flex items-center">
              <div className="text-white max-w-xl">
                <p className="text-xl md:text-lg mb-2 text-[#a8741a]">
                  {slide.offer}
                </p>
                <h1 className="text-5xl md:text-4xl font-bold mb-2 font-baloo">
                  {slide.title}
                </h1>
                <span className="text-2xl md:text-lg text-gray-300 block mb-8">
                  {slide.subtitle}
                </span>
                <p className="text-lg md:text-base mb-4">
                  Starting at{' '}
                  <span className="text-[#a8741a] font-semibold">
                    {slide.price}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 transition duration-300"
        aria-label="Previous Slide"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 transition duration-300"
        aria-label="Next Slide"
      >
        &#10095;
      </button>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full ${currentSlide === index ? 'bg-[#a8741a]' : 'bg-gray-400'} transition duration-300`}
            aria-label={`Go to slide ${index + 1}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Homepage;
