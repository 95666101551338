import React from 'react';
import img1 from '../../img/Gold-Bride-home.png';
import img2 from '../../img/Diamond-Bride-home.png';
import img3 from '../../img/Antique-Bride-home.png';
import bgimg from '../../img/Pattern-Background-Jpg.jpg';

function Types() {
    return (
        <div 
            className="bg-cover bg-center py-12" 
            style={{ backgroundImage: `url(${bgimg})` }}
        >
            <div className="text-center mb-4">
                <h1 className="text-4xl font-bold text-white">BRIDES OF INDIA</h1>
            </div>
            <div className="container mx-auto justify-center grid grid-cols-1 md:grid-cols-3 gap-1 px-4">
                <div className="text-center">
                    <img src={img1} className="w-auto h-96 mb-4 mx-auto" alt="Golden Bride - Expression of breathtaking ethnics" />
                    <h3 className="text-2xl font-semibold mb-2 ">The Golden Bride</h3>
                    <p className="text-gray-700">An expression of breathtaking ethnics</p>
                </div>
                <div className="text-center">
                    <img src={img2} className="w-auto h-96 mb-4 mx-auto" alt="Diamond Bride - Celebration of glam light and shimmer" />
                    <h3 className="text-2xl font-semibold mb-2 ">The Diamond Bride</h3>
                    <p className="text-gray-700">A celebration of glam light and shimmer</p>
                </div>
                <div className="text-center">
                    <img src={img3} className="w-auto h-96 mb-4 mx-auto" alt="Antique Bride - Treasure of magnificent art and culture" />
                    <h3 className="text-2xl font-semibold mb-2 ">The Antique Bride</h3>
                    <p className="text-gray-700">A treasure of magnificent art and culture</p>
                </div>
            </div>
        </div>
    );
}

export default Types;
