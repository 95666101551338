import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../img/home_ring.5188983de499be0bf989.jpeg";
import img2 from "../../img/home_earing.02f058797881f4e26ac8.jpeg";
import img3 from "../../img/home_pendants.4fa3ea761462f4722678.jpeg";
import img4 from "../../img/home_bangles.0b39f2367e2c21a470a1.jpeg";
import img5 from "../../img/home_diamond.fe429005f5779d067294.jpg";
import img6 from "../../img/home_bracelate.c5fd4cb31f943ae94960.jpeg";
import img7 from "../../img/home_necklaces.454a60764ef53bec0351.jpeg";
import img8 from "../../img/home_mangalsutra.845124e1d88e01c87210.jpeg";

function Categories() {
  const navigate = useNavigate();

  const handleSubCategoryClick = (subCategoryId) => {
    if (!subCategoryId) {
      console.error("Invalid subCategoryId:", subCategoryId);
      return;
    }
    navigate(`/category/${subCategoryId}`);
  };

  return (
    <div className="container mx-auto py-16">
      <h2 className="text-4xl font-bold text-center mb-8">Shop By Category</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-6 pt-8">
        <div onClick={() => handleSubCategoryClick(7)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img1} alt="Rings" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">RINGS</p>
        </div>
        <div onClick={() => handleSubCategoryClick(5)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img2} alt="Earrings" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">EARRINGS</p>
        </div>
        <div onClick={() => handleSubCategoryClick(6)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img3} alt="Pendants" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">PENDANTS</p>
        </div>
        <div onClick={() => handleSubCategoryClick(9)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img4} alt="Bangles" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">BANGLES</p>
        </div>
        <div onClick={() => handleSubCategoryClick(15)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img5} alt="Diamond" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">DIAMOND</p>
        </div>
        <div onClick={() => handleSubCategoryClick(5)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img6} alt="Bracelet" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">BRACELET</p>
        </div>
        <div onClick={() => handleSubCategoryClick(7)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img7} alt="Necklaces" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">NECKLACES</p>
        </div>
        <div onClick={() => handleSubCategoryClick(8)} className="flex flex-col items-center group cursor-pointer">
          <div className="bg-gray-200 p-4 rounded-full shadow-lg group-hover:animate-bounce">
            <img src={img8} alt="Mangalsutra" className="w-28 h-28 object-cover rounded-full shadow-xl" />
          </div>
          <p className="mt-4 text-lg font-medium">MANGALSUTRA</p>
        </div>
      </div>
    </div>
  );
}

export default Categories;
