import React, { useState } from 'react';
import './LeftAnimation.css'

function CustomerContact() {
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [pincode, setPincode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');

  // Function to simulate fetching city and state by pincode
  const handlePincodeChange = (e) => {
    const newPincode = e.target.value;
    setPincode(newPincode);

    // Simulating fetching city and state from pincode
    if (newPincode.length === 6) {
      // Simulated city and state based on pincode
      setCity('Mumbai'); // Example city
      setState('Maharashtra'); // Example state
    } else {
      setCity('');
      setState('');
    }
  };

  return (
    <div className=" flex flex-col items-center justify-center animate-slideInFromLeft">
      <div className="min-h-screen w-full flex items-center justify-center bg-transparent">
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md transition-all duration-700">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Customer Contact</h2>

        {/* Mobile Number Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Mobile Number</label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder="Enter your mobile number"
          />
        </div>

        {/* Email Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
          <input
            type="email"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
        </div>

        {/* Pincode Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Pincode</label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
            value={pincode}
            onChange={handlePincodeChange}
            placeholder="Enter your pincode"
          />
        </div>

        {/* City and State (Fetched automatically) */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">City</label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
            value={city}
            readOnly
            placeholder="City will be fetched automatically"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">State</label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
            value={state}
            readOnly
            placeholder="State will be fetched automatically"
          />
        </div>

        {/* Address Line 1 */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Address Line 1</label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
            placeholder="Enter Address Line 1"
          />
        </div>

        {/* Address Line 2 */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Address Line 2</label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#F0B90B]"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
            placeholder="Enter Address Line 2"
          />
        </div>

        {/* Submit Button */}
        <button
          className="w-full bg-[#F0B90B] text-white py-3 rounded-lg shadow hover:bg-[#d39e0b] transition-all duration-300"
          onClick={() => console.log({ mobile, email, pincode, city, state, addressLine1, addressLine2 })}
        >
          Submit
        </button>
      </div>
    </div>
    </div>
  );
}

export default CustomerContact;
