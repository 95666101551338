import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import Home from "./Component/Home/Home";
import About from "./Component/About/About";
import Contact from "./Component/Contact/Contact";
import Collection from "./Component/Collections/Collection";
import ProductPage from "./Component/Categories/ProductPage";
import LoginPage from "./Component/Categories/LoginPage";
import SignUpPage from "./Component/Categories/SignUpPage";
import CustomerContact from "./Component/Categories/CustomerContact";
import CategoryPage from "./Component/Desin/Category";
import OTPVerification from "./Component/Categories/MobileName";
import MetalPage from "./Component/Desin/MetalPage";
import Collectioncategories from "./Component/Collections/Collectioncategories";

function App() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchProductsBySubCategory = async (subCategoryId) => {
    const apiUrl = `https://shrishivshankarjewellers.com/exe_files/list_sub_category_products.php?SubCategoryId=${subCategoryId}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setProducts(data.data);
      } else {
        console.error("Product data is not an array", data);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchProductsByMetal = async (metalId) => {
    try {
      const response = await fetch(`https://shrishivshankarjewellers.com/exe_files/list_metal_products.php?MetalId=${metalId}`);
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setProducts(data.data);
      } else {
        console.error("Metal product data is not an array", data);
      }
    } catch (error) {
      console.error("Error fetching metal products:", error);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const categoryResponse = await fetch("https://shrishivshankarjewellers.com/exe_files/categories.php");
        const categoryData = await categoryResponse.json();
        if (Array.isArray(categoryData)) {
          setCategories(categoryData);
        } else {
          console.error("Categories data is not an array", categoryData);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchInitialData();
  }, []);

  return (
    <div className="App">
      <Router>
        <Navbar
          categories={categories}
          fetchProductsBySubCategory={fetchProductsBySubCategory}
          fetchProductsByMetal={fetchProductsByMetal}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/collections" element={<Collection />} />
          <Route path="/product_cart" element={<ProductPage />} />
          <Route path="/customer_name" element={<OTPVerification />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign_up" element={<SignUpPage />} />
          <Route path="/customer_contact" element={<CustomerContact />} />
          <Route path="/product/:productId" element={<ProductPage />} />
          <Route path="/category/:subCategoryId" element={<CategoryPage products={products} />} />
          <Route path="/metal/:metalId" element={<MetalPage products={products} />} />
          <Route path="/collection/:collectionId" element={<Collectioncategories />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
