import React from "react";
import img1 from "../../img/gift1.png";
import img2 from "../../img/gift2.png";
import img3 from "../../img/gift3.png";
import img4 from "../../img/gift4.png";
import img5 from "../../img/gift5.png";
import img6 from "../../img/gift6.png";

function Gift() {
  return (
    <div className="bg-gray-50 py-12">
      <div className="text-center mb-10">
        <h1 className="text-4xl font-bold text-[#b9a02e] mb-4">Gift Collection</h1>
        <p className="text-gray-600 text-lg">
          Discover our exclusive gift collection for every occasion.
        </p>
      </div>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 px-4">
        {/* Image Cards */}
        {[img1, img2, img3, img4, img5, img6].map((image, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <img
              src={image}
              alt={`Gift ${index + 1}`}
              className="w-full h-64 object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gift;
